// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



import jQuery from 'jquery';
window.$ = window.jQuery = jQuery


const fancybox = require("@fancyapps/fancybox")
const fancyboxCSS =
import('@fancyapps/fancybox/dist/jquery.fancybox.css')


import 'bootstrap-icons/font/bootstrap-icons.css'





require("/usr/home/huiskes/sites/www.huiskesmode.nl/shared/bundle/ruby/3.2.0/bundler/gems/spree_bp_core4-3e201bc35b4b/app/javascript/spree_bp_core4.js")
